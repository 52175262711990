import memoizee from 'memoizee';
import type { TRegionNameEnglish } from '@shared/jp/shipping/interfaces';
import {
  SHIPPING_METHOD_PREMIUM,
  SHIPPING_METHOD_REGULAR,
} from '@shared/common/shipping/constants';
import { CURRENCY } from '@shared/jp/constants';
import format from 'date-fns/format';
import type {
  TShippingMap,
  TShippingMethodsMap,
} from '@shared/jp/production/interfaces';
import { getExpectedArrivalDate } from '@shared/common/production/utils';
import { getRegionByNameEnglish } from '@shared/jp/shipping/utils';
import type { IShipping as IShippingFE } from '@shared/jp/data/shipping/types';

export const getShippingRegular = memoizee(
  (
    expectedArrivalDate: string,
    regionName: TRegionNameEnglish,
    price: number,
  ): IShippingFE => ({
    id: SHIPPING_METHOD_REGULAR,
    name: 'メール便（ネコポス）',
    description: /* html */ `ポスト投函・追跡あり・日時指定不可・<b>1冊</b>まで<br>全国一律料金`,
    price,
    currency: CURRENCY,
  }),
);

export const getShippingPremium = memoizee(
  (
    expectedArrivalDate: string,
    regionName: TRegionNameEnglish,
    price: number,
  ): IShippingFE => ({
    id: SHIPPING_METHOD_PREMIUM,
    name: '宅急便（ヤマト運輸）',
    description: /* html */ `対面受け渡し・追跡あり・日時指定は問い合わせから・同時購入4冊まで<br>兵庫県から${
      getRegionByNameEnglish(regionName).name
    }への送料`,
    price,
    currency: CURRENCY,
  }),
);

export const formatDate = (
  date: [Date] | [Date, Date],
  _format = 'MMM d',
): string => {
  if (date.length === 1) {
    return format(date[0], _format);
  }

  return `${format(date[0], _format)} - ${format(date[1], _format)}`;
};

/** @returns all available shippings */
export const getShippings = (
  booksCount: number,
  expectedShippingDate: Date,
  regionName: keyof TShippingMap,
  shippingMethodsMap: TShippingMethodsMap,
  // exceptions: IException[],
) => [
  getShippingRegular(
    formatDate(
      getExpectedArrivalDate(
        expectedShippingDate,
        regionName,
        shippingMethodsMap[SHIPPING_METHOD_REGULAR],
        // exceptions,
      ),
    ),
    regionName,
    // @ts-ignore
    shippingMethodsMap[SHIPPING_METHOD_REGULAR][regionName].prices[
      booksCount - 1
    ],
  ),
  getShippingPremium(
    formatDate(
      getExpectedArrivalDate(
        expectedShippingDate,
        regionName,
        shippingMethodsMap[SHIPPING_METHOD_PREMIUM],
        // exceptions,
      ),
    ),
    regionName,
    // @ts-ignore
    shippingMethodsMap[SHIPPING_METHOD_PREMIUM][regionName].prices[
      booksCount - 1
    ],
  ),
];

/** comparing chippings by id */
export const compareShippings = (
  shippingA: IShippingFE,
  shippingB: IShippingFE,
): boolean => shippingA.id === shippingB.id;
