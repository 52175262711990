import { Component } from '@angular/core';
import type { IBookInputNameToValueMap } from '@shared/common/data/books/inputs/types';
import { Logger } from '@shared/common/logger';
import TokyoMetro from 'src/app/data/books/TokyoMetro';
import { DiscountService } from '@studiobuki/web-core/lib/discount';
import { getBooksDiscount } from '@shared/jp/discount/utils';
import { MediaService } from '@studiobuki/web-core/lib/media';
import { faqDataCondensed } from 'src/app/data/faq/data';
import type { ICommonBookData } from '@shared/jp/models';
import {
  INPUT_NAME_CHARACTER,
  INPUT_NAME_GENDER,
  INPUT_NAME_GLASSES,
  INPUT_NAME_KIDS_NAME,
} from '@shared/common/book/constants';
import {
  INPUT_NAME_KUN_CHAN,
  INPUT_NAME_TRAIN_LINE,
} from '@shared/jp/book/constants';
import { Router } from '@angular/router';
import { stepsData } from 'src/app/data/steps/data';
import { ROUTE_PATH_FAQ } from '@studiobuki/web-core/lib/routing';
import { GAGetEvents, GATrack } from 'src/analytics';
import { ALIAS_TO_ID_MAP } from '@shared/jp/book/maps';
import type { TFeaturesData } from '@studiobuki/web-core/lib/section-features';
import { BookTokyoMetroService } from '../book-page/pages/book-tokyo-metro-page/book-tokyo-metro.service';

const log = new Logger('HomePageComponent');

@Component({
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent {
  public readonly book = TokyoMetro;

  public readonly getBooksDiscount = getBooksDiscount;

  public readonly featuresData: TFeaturesData = [
    {
      imgSrc: 'assets/images/feature1.png',
      text: ['世界に一冊の', '特別な絵本'],
    },
    {
      imgSrc: 'assets/images/feature2.png',
      text: ['送料 ¥420 (メール便)', '¥780～ (宅急便)'],
    },
    {
      imgSrc: 'assets/images/feature3.png',
      text: ['環境に配慮した素材を使用'],
    },
    {
      imgSrc: 'assets/images/feature4.png',
      text: ['お祝いやお誕生日の', 'プレゼントに'],
    },
  ];

  public readonly faqDataCondensed = faqDataCondensed;

  public readonly sectionSteps = stepsData;

  public loading = false;

  constructor(
    public discountService: DiscountService,
    public mediaService: MediaService,
    private _bookTokyoMetroService: BookTokyoMetroService,
    private _router: Router,
  ) {}

  public async submitted(value: IBookInputNameToValueMap) {
    this.loading = true;

    log.info('submitted', value);
    // const { alias, gift } = this;

    const common: Partial<ICommonBookData> = {};

    // if (gift) {
    //   common.gift = gift;

    //   const userGift = await this._firebaseService.getUserGift(gift);

    //   if (checkUserGift(userGift)) {
    //     common.cover = userGift.book.cover;
    //   }
    // }

    const newBookId = await this._bookTokyoMetroService.createBook(
      {
        // @ts-ignore
        heroName: value[INPUT_NAME_KIDS_NAME],
        // @ts-ignore
        kunChan: value[INPUT_NAME_KUN_CHAN],
        // @ts-ignore
        trainLine: value[INPUT_NAME_TRAIN_LINE],
        // @ts-ignore
        gender: value[INPUT_NAME_GENDER],
        // @ts-ignore
        character: value[INPUT_NAME_CHARACTER],
        // @ts-ignore
        glasses: value[INPUT_NAME_GLASSES],
      },
      common,
    );

    log.info('created book', newBookId);

    await this._router.navigate(['book', newBookId], {
      // replaceUrl: true,
      // queryParams: {
      //   [ROUTE_QUERY_PARAM_GIFT]: gift,
      // },
    });

    log.info('submit', /* alias, */ value);

    const bookAliasId = ALIAS_TO_ID_MAP[this.book.alias];

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!bookAliasId) {
      log.error(`didn't manage to get bookAliasId by alias`, {
        alias: this.book.alias,
        bookAliasId,
      });
      return;
    }

    GATrack(GAGetEvents.getBookPreview(bookAliasId));
  }

  public onFaqButtonClick() {
    log.info('onFaqButtonClick');
    return this._router.navigate([ROUTE_PATH_FAQ]);
  }
}
