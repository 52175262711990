import { Component } from '@angular/core';
import { ROUTE_PATH_CONTACTS } from '@studiobuki/web-core/lib/routing';
import type { IArticle } from '@studiobuki/web-core/lib/section-text';
import { BASE_HREF } from 'src/app/app.contants';

const contactsHref = `${BASE_HREF}${ROUTE_PATH_CONTACTS}`;

@Component({
  templateUrl: './ordercontract-page.component.html',
  styleUrls: ['./ordercontract-page.component.scss'],
})
export class OrdercontractPageComponent {
  public readonly articles: IArticle[] = [
    {
      caption: '特定商取引法に基づく表示',
      passages: [
        {
          items: [
            {
              type: 'text',
              text: /* html */ `販売業者：<br><a href="https://hellobuki.com/jp/" >STUDIO BUKI株式会社`,
            },
          ],
        },
        {
          items: [
            {
              type: 'text',
              text: '住所：<br>〒154-0013<br>東京都世田谷区駒沢公園1-1 Tote駒沢公園',
            },
          ],
        },
        {
          items: [
            {
              type: 'text',
              text: '運営責任者：<br>コズロブ ふくみ',
            },
          ],
        },
        {
          items: [
            {
              type: 'text',
              text: `お問い合せ先：<br>yay@hellobuki.comにメール、もしくは<a href="${contactsHref}">お問い合わせページ</a>よりご連絡お願いします`,
            },
          ],
        },
        {
          items: [
            {
              type: 'text',
              text: '電話番号：<br>カスタマーサポート 050-1724-5838（平日10~16時、祝日・連休・年末年始除く）',
            },
          ],
        },
        {
          items: [
            {
              type: 'text',
              text: '商品代金以外の必要料金：<br>表示された販売価格（消費税込）に加え、送料が別途かかります。',
            },
          ],
        },
        {
          items: [
            {
              type: 'text',
              text: 'お支払い方法：<br>各種クレジットカードまたはコンビニ払い（ファミリーマート、ローソン、ミニストップ、セイコーマート）がご利用いただけます。',
            },
          ],
        },
        {
          items: [
            {
              type: 'text',
              text: '商品の引き渡し時期：<br>ご注文後３営業日後に発送いたします。<br>*年末年始など長期休暇の場合は通常よりも発送までに日数がかかります。その場合はwebサイト上でお知らせします。',
            },
          ],
        },
        {
          items: [
            {
              type: 'text',
              text: '商品の返品・交換について：<br>オーダーメイド商品のため、お客さま都合の返品・交換はできません。\n万一、商品に不備（落丁、乱丁、印刷ミス、破損、汚れ等）があった場合は、恐れ入りますが発送から14日以内に <a href="mailto:yay@hellobuki.com">yay@hellobuki.com</a> に【ご注文番号】を記載のうえ、メールでご連絡ください。新しいものと交換させていただきます。',
            },
          ],
        },
      ],
    },
  ];
}
