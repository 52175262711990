import type { AfterViewInit } from '@angular/core';
import { Component } from '@angular/core';
import { YotpoV3Service } from '@studiobuki/web-core/lib/yotpo-v3';

@Component({
  templateUrl: './reviews-page.component.html',
  styleUrls: ['./reviews-page.component.scss'],
})
export class ReviewsPageComponent implements AfterViewInit {
  constructor(private _yotpo: YotpoV3Service) {}

  ngAfterViewInit(): void {
    this._yotpo.init();
  }
}
