<div class="wrap">
  <app-section-header-gap class="wrap__header"></app-section-header-gap>
  <div class="wrap__container">
    <!-- <div id="yotpo-testimonials-custom-tab"></div> -->
    <div
      class="yotpo-widget-instance"
      data-yotpo-instance-id="1019713"
    ></div>
  </div>
</div>
